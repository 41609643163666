<template>
  <div class="PetitionCase">
    <ShellHeader @click="$emit('closeDetailModal')">
      <div class="petition_case_notice">
        <span>選服陳情案件</span>
        <span>因陳情案件涉及民眾個人資訊及隱私，僅呈現精選案件12則</span>
      </div>
    </ShellHeader>
    <div class="petition_case_body">
      <div class="side_box"></div>
      <div class="petition_case_wrap" ref="proposalResultWrap">
        <template v-for="(row, index) in listData">
          <div
            class="petition_case_content"
            :key="index"
            v-if="row && Object.keys(row).length !== 0"
          >
            <BaseInfo>
              <div class="case_name">{{ index + 1 }}.{{ row.案件標題 }}</div>
              <div class="case_info">
                <span>
                  性質：<time>{{ row.性質 }}</time>
                </span>
                <span>活動時間：{{ row.時間 }}</span>
                <span>活動地點：{{ row["陳情來源／區域"] }}</span>
                <span>主責單位：{{ row.主責單位 }}</span>
              </div>
            </BaseInfo>
            <div class="case_content">
              <span class="case_subtitle">案件內容： </span>
              <p>
                {{ row.案件內容 }}
              </p>
            </div>
            <div v-if="isShowLink(row)" class="case_sns">
              <span class="case_subtitle">相關連結：</span>
              <a :href="row.相關連結1" target="_blank">{{ row.相關連結1 }}</a>
              <a :href="row.相關連結2" target="_blank">{{ row.相關連結2 }}</a>
              <a :href="row.相關連結3" target="_blank">{{ row.相關連結3 }}</a>
            </div>
            <div v-if="row.照片" class="case_image">
              <p class="case_subtitle">相關照片：</p>
              <Lightbox :items="row.照片 && row.照片.map((i) => i.url)" />
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import airtable from "@/mixins/airtable.vue";
import ShellHeader from "./ShellHeader.vue";
import popupVue from "../../mixins/popup.vue";
import Lightbox from "@/components/Lightbox.vue";
import BaseInfo from "@/components/Popover/BaseInfo.vue";
export default {
  name: "PetitionCase",
  components: {
    ShellHeader,
    Lightbox,
    BaseInfo,
  },
  mixins: [airtable, popupVue],
  data() {
    return {};
  },
  mounted() {
    this.getData("選服陳情案件", {
      sort: [{ field: "時間", direction: "desc" }],
    });
  },
  props: {
    title: {
      type: String,
    },
    displayDefaultValue: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isShowLink(row) {
      return Object.keys(row).find((keyName) => keyName.includes("相關"));
    },
  },
};
</script>

<style lang="scss" scoped>
.PetitionCase {
  @include popup;
}

.petition_case {
  &_notice {
    display: flex;
    flex-direction: column;
    @include media(lg) {
      flex-direction: row;
      align-items: flex-end;
    }
    span:first-child {
      margin-bottom: 16px;
      font-size: 36px;
      @include media(lg) {
        font-size: 36px;
        margin: 0;
      }
    }
    span:last-child {
      font-size: 15px;
      line-height: 1.2;
      @include media(lg) {
        margin-left: 10px;
      }
    }
  }
  &_body {
    height: 100%;
    @include media(md) {
      display: flex;
    }
  }
  &_wrap {
    flex: 1 0 0;
  }
}

.petition_case_content {
  padding: 16px;
  border-bottom: solid 20px $red-600;
  @include media(md) {
    padding: 50px 55px;
  }
}

.case {
  &_content {
    margin-bottom: 16px;
    p {
      margin: 4px 0 0 0;
      white-space: pre-line;
    }
  }
  &_sns {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    a {
      width: fit-content;
      text-decoration: underline;
    }
  }
}
</style>
