<template>
  <div class="side_nav_shell">
    <div class="side_nav_shell_sort_phone">
      <b-form-select
        class="side_nav_shell_select"
        :options="sortItems"
        :value="dataType"
        @input="$emit('dataTypeChanged', $event)"
        value-field="type"
      >
      </b-form-select>
    </div>
    <div class="side_nav_shell_body">
      <div class="side_nav_shell_sort_laptop">
        <div
          v-for="(item, index) in sortItems"
          :key="item.type"
          class="side_nav_shell_sort_item"
          :class="{ data_active: dataType === item.type }"
          @click="$emit('dataTypeChanged', item.type)"
        >
          <slot name="list" :item="item" :index="index">
            <span class="data_name">{{ item.type }}</span>
          </slot>
        </div>
      </div>
      <div class="side_nav_shell_wrap">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SideNavShell",
  // emit:[ "dataTypeChanged" ]
  props: {
    // [ { type, text } ]
    sortItems: {
      type: Array,
      required: true,
    },
    dataType: {
      type: String,
      required: true,
    },
  },
  created() {
    if (this.sortItems && this.sortItems.length) {
      this.$emit("dataTypeChanged", this.sortItems[0].type);
    }
  },
};
</script>

<style lang="scss" scoped>
.side_nav_shell {
  --select-height: 60px;
  &_body {
    overflow: auto;
    height: calc(100% - var(--select-height));
    @include media(md) {
      display: flex;
      height: 100%;
    }
  }
  &_wrap {
    flex: 2 0 0;
    overflow: auto;
    height: 100%;
  }
  &_content {
    padding: 16px;
    border-bottom: solid 20px $red-600;
    @include media(md) {
      padding: 50px 55px;
    }
    figure {
      display: inline-block;
      p {
        margin: 0;
      }
    }
  }
}

.side_nav_shell_select {
  width: 100%;
  height: var(--select-height);
  padding: 0 16px;
  border: solid 1px $white;
  background: url("~@/assets/image/icon/triangle-white.png") $red-600 no-repeat
    right 1rem center;
  font-size: 17px;
  color: $white;
  font-size: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  @include media(lg) {
    display: none;
  }
}

.side_nav_shell_sort {
  &_laptop {
    display: none;
    @include media(lg) {
      flex: 0 0 400px;
      display: block;
      background-color: $red-600;
      max-height: 100%;
      overflow-y: auto;
    }
  }
  &_item {
    height: 80px;
    padding: 22.5px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-right: solid 1px $white;
    border-bottom: solid 1px $white;
    background-color: $red-600;
    cursor: pointer;
    &.data_active {
      background-color: #fff;
      span {
        color: $red-600;
      }
    }
  }
}
.data_length {
  font-size: 30px;
  font-weight: $block;
  color: $white;
}
.data_name {
  font-size: 24px;
  font-weight: $bold;
  color: $white;
}
</style>
