<template>
  <div class="WrittenInquiry">
    <div
      class="proposal_result_content"
      v-for="(row, index) in listData"
      :key="index"
    >
      <BaseInfo>
        <p class="case_name">{{ index + 1 }}.{{ row.摘要 }}</p>
        <div class="case_info">
          <span> 提案日期：{{ row.日期 }} </span>
        </div>
      </BaseInfo>
      <div class="case_content">
        <p class="case_subtitle">案由:</p>
        <p>{{ row.案由 }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import airtable from "@/mixins/airtable.vue";
import BaseInfo from "@/components/Popover/BaseInfo.vue";
export default {
  name: "OralInterpellation",
  components: {
    BaseInfo,
  },
  mixins: [airtable],
  mounted() {
    this.getData("書面質詢", {
      sort: [{ field: "日期", direction: "desc" }],
    });
  },
};
</script>

<style lang="scss" scoped>
.proposal_result_content {
  padding: 16px;
  border-bottom: solid 20px $red-600;
  @include media(md) {
    padding: 50px 55px;
  }
}
</style>
