<template>
  <b-form-select
    v-bind="$attrs"
    v-on="$listeners"
    value-field="type"
    class="base_select"
  >
    <template #first>
      <b-form-select-option :value="null">{{ selectRef }}</b-form-select-option>
    </template>
    <slot />
  </b-form-select>
</template>
<script>
export default {
  props: {
    selectRef: {
      type: String,
      required: true,
    },
  },
  inheritAttrs: false,
};
</script>
<style lang="scss" scoped>
.base_select {
  min-width: 100px;
  padding: 10px;
  padding-right: 40px;
  border: solid 1px #a63f24;
  background: url("~@/assets/image/icon/triangle-red.png") white no-repeat right
    1rem center;
  color: #333333;
  font-size: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex: 1;
  @include media(lg) {
    flex: initial;
  }
  &::v-deep option:disabled {
    background-color: $gray-300;
  }
}
</style>
