<script>
export default {
  computed: {
    // sortItems() {
    //   const result = [];
    //   this.listData.forEach((item) => {
    //     if (!result.includes(item[this.firstRef])) result.push(item[this.firstRef]);
    //   });
    //   return result.map((item) => ({ type: item, text: item }));
    // },

    // 需要個別在 component 內定義 this.firstRef
    firstSortedData() {
      return this.listData.reduce((accumulator, item) => {
        if (
          !Object.prototype.hasOwnProperty.call(
            accumulator,
            item[this.firstRef]
          )
        ) {
          accumulator[`${item[this.firstRef]}`] = [];
        }
        accumulator[`${item[this.firstRef]}`].push(item);
        return accumulator;
      }, {});
    },
  },
};
</script>
