<template>
  <div class="ProposalCase">
    <div
      class="proposal_result_content"
      v-for="(row, index) in listData"
      :key="index"
    >
      <BaseInfo>
        <p class="case_name">{{ index + 1 }}.{{ row.提案名稱 }}</p>
        <div class="case_info">
          <span>提案日期：{{ row.提案日期 }}</span>
          <span>主提案人：{{ row.主提案人 }}</span>
        </div>
      </BaseInfo>
      <div class="case_content">
        <p class="case_subtitle">內容關鍵字:</p>
        <p>{{ row.內容關鍵字 }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import airtable from "@/mixins/airtable.vue";
import BaseInfo from "@/components/Popover/BaseInfo.vue";
export default {
  name: "ProposalJointCase",
  components: {
    BaseInfo,
  },
  mixins: [airtable],
  mounted() {
    this.getData("法律共同提案", {
      sort: [{ field: "提案日期", direction: "desc" }],
    });
  },
};
</script>

<style lang="scss" scoped>
.proposal_result_content {
  padding: 16px;
  border-bottom: solid 20px $red-600;
  @include media(md) {
    padding: 50px 55px;
  }
}
</style>
