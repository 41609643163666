<template>
  <div class="OralInterpellation">
    <SelectContainer>
      <BaseSelect
        v-model="secondSortSelected"
        :options="secondOptions"
        :selectRef="secondRef"
        @input="backToTop"
      />
    </SelectContainer>
    <div
      class="proposal_result_content"
      v-for="(row, index) in secondSortedData.slice(0, amount)"
      :key="index"
    >
      <div
        class="case_video"
        v-if="
          row['YT連結/資料連結'] && row['YT連結/資料連結'].includes('youtube')
        "
      >
        <iframe
          id="ytplayer"
          type="text/html"
          width="100%"
          height="100%"
          :src="
            'https://www.youtube.com/embed/' +
            youtube_parser(row['YT連結/資料連結'])
          "
          frameborder="0"
        ></iframe>
      </div>
      <figure>
        <BaseInfo>
          <p class="case_name">{{ index + 1 }}.{{ row.title }}</p>
          <div class="case_info">
            <span name="case_date"> 質詢時間：{{ row.質詢時間 }} </span>
            <span name="case_location"> 主辦單位：{{ row.主辦單位 }} </span>
          </div>
        </BaseInfo>
        <p class="field-content" v-html="row.內容大綱"></p>
        <div
          v-if="
            row['YT連結/資料連結'] &&
            !row['YT連結/資料連結'].includes('youtube')
          "
        >
          <a target="_blank" :href="row['YT連結/資料連結']">資料連結</a>
        </div>
      </figure>
    </div>
    <div class="btn-more" @click="amount += 6">讀取更多</div>
    <div class="empty-space"></div>
  </div>
</template>

<script>
import secondSort from "@/mixins/secondSort.vue";
import BaseSelect from "../BaseSelect.vue";
import BaseInfo from "@/components/Popover/BaseInfo.vue";
import SelectContainer from "../SelectContainer.vue";
export default {
  name: "OralInterpellation",
  components: { BaseSelect, SelectContainer, BaseInfo },
  mixins: [secondSort],
  data() {
    return {
      totalList: [],
      amount: 6,
    };
  },
  computed: {
    secondRef() {
      return "主辦單位";
    },
    _secondVaildOptions() {
      const result = [];
      this.totalList.forEach((item) => {
        if (!result.includes(item[this.secondRef]))
          result.push(item[this.secondRef]);
      });
      return result;
    },
    secondSortedData() {
      if (!this._secondVaildOptions.includes(this.secondSortSelected)) {
        return this.totalList;
      } else {
        return this.totalList.filter((i) => {
          if (i[this.secondRef] === this.secondSortSelected) return i;
        });
      }
    },
    orderSecondOptions() {
      return [
        "院會",
        "財政委員會",
        "內政委員會",
        "交通委員會",
        "經濟委員會",
        "外交及國防委員會",
        "司法及法制委員會",
        "教育及文化委員會",
        "社會福利及衛生環境委員會",
      ];
    },
  },
  watch: {
    secondSortSelected(newValue) {
      this.dataType = newValue;
      this.amount = 6;
    },
  },
  mounted() {
    this.$base("口頭質詢")
      .select({ sort: [{ field: "質詢時間", direction: "desc" }] })
      .all()
      .then((results) => {
        this.totalList = results.map((record) => record.fields);
      });
  },
  methods: {
    youtube_parser(url) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return match && match[7].length == 11 ? match[7] : false;
    },
    backToTop() {
      this.$el.scroll(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.proposal_result_content {
  padding: 16px;
  border-bottom: solid 20px $red-600;
  @include media(md) {
    padding: 50px 55px;
    display: flex;
    gap: 16px;
  }
  figure {
    flex: 1 0 0;
  }
}
.video_button {
  width: 50px;
  height: 50px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // background-color: $white;
}
</style>
