<template>
  <div class="Survey">
    <ShellHeader title="會勘及地方建設" @click="$emit('closeDetailModal')" />
    <SideNavShell
      :sortItems="sortItems"
      :dataType="dataType"
      @dataTypeChanged="dataType = $event"
    >
      <template #list="{ item }">
        <span class="data_name">{{ item.type }}</span>
        <animated-number
          :formatValue="($event) => Math.floor($event)"
          :value="surveyLength[item.type]"
          :duration="3000"
          class="data_length"
        />
      </template>
      <template #default>
        <RepresentativeSurvey
          v-if="!dataType || dataType === '代表性地方建設'"
        />
        <SurveyResult
          v-else
          :dataType="dataType"
          :listData="areaSurveys[dataType] || []"
        />
      </template>
    </SideNavShell>
  </div>
</template>

<script>
import ShellHeader from "../ShellHeader.vue";
import SideNavShell from "../SideNavShell.vue";
import SurveyResult from "./SurveyResult.vue";
import RepresentativeSurvey from "./RepresentativeSurvey.vue";
import popupVue from "../../../mixins/popup.vue";

export default {
  name: "Survey",
  mixins: [popupVue],
  components: {
    SideNavShell,
    SurveyResult,
    RepresentativeSurvey,
    ShellHeader,
  },
  props: {
    displayDefaultValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataType: "代表性地方建設",
      areaSurveys: {},
      sortItems: [],
      surveyLength: {},
    };
  },
  mounted() {
    this.$base("會勘及地方建設(一覽)")
      .select({ sort: [{ field: "辦理日期", direction: "desc" }] })
      .all()
      .then((results) => {
        this.areaSurveys = results.reduce((accumulator, item) => {
          const data = item.fields;
          if (!accumulator[data.區域]) {
            accumulator[data.區域] = [];
          }
          accumulator[data.區域].push(data);
          return accumulator;
        }, {});

        this.surveyLength = {
          ...this.$store.getters.length_all,
          ...Object.keys(this.areaSurveys).reduce((accumulator, key) => {
            accumulator[key] = this.areaSurveys[key].length;
            return accumulator;
          }, {}),
        };

        this.sortItems = [
          {
            type: "代表性地方建設",
            text: `代表性地方建設(${this.surveyLength["代表性地方建設"]})`,
          },
        ].concat(
          ["霧峰", "沙鹿", "大肚", "烏日", "龍井", "其他"].map((i) => {
            return {
              type: i,
              text: `${i}(${this.areaSurveys[i].length})`,
            };
          })
        );
      });
  },
};
</script>
<style lang="scss" scoped>
.Survey {
  @include popup;
}
</style>
