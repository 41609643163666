<script>
export default {
  data() {
    return {
      secondSortSelected: null,
    };
  },
  computed: {
    _secondVaildOptions() {
      const result = [];
      this.listData.forEach((item) => {
        if (!result.includes(item[this.secondRef]))
          result.push(item[this.secondRef]);
      });
      return result;
    },
    secondOptions() {
      if (this.orderSecondOptions) {
        return this.orderSecondOptions.map((orderOption) =>
          this._secondVaildOptions.includes(orderOption)
            ? { type: orderOption, text: orderOption }
            : { type: orderOption, text: orderOption, disabled: true }
        );
      } else {
        return this._secondVaildOptions.map((item) => ({
          type: item,
          text: item,
        }));
      }
    },
    // 需要個別在 component 內定義 this.secondRef
    secondSortedData() {
      const income =
        (this.firstSortedData && this.firstSortedData[this.dataType]) ||
        this.listData;
      if (!this._secondVaildOptions.includes(this.secondSortSelected)) {
        return income;
      } else {
        return income.filter((i) => {
          if (i[this.secondRef] === this.secondSortSelected) return i;
        });
      }
    },
  },
};
</script>
