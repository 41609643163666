<template>
  <div class="side_nav_shell_header">
    <h5 class="side_nav_shell_title">
      <slot>
        <span>{{ title }}</span>
      </slot>
    </h5>
    <button type="button" class="close_button" v-on="$listeners">
      <closeIcon />
    </button>
  </div>
</template>
<script>
// svg loader
import closeIcon from "@/assets/image/icon/close-icon.svg";
export default {
  inheritAttrs: false,
  components: {
    closeIcon,
  },
  props: {
    title: {
      type: String,
    },
  },
};
</script>
<style lang="scss">
.side_nav_shell {
  &_header {
    padding: 23px 16px;
    position: relative;
    background-color: $red-600;
    border-bottom: solid 1px $white;
    @include media(lg) {
      height: 86px;
      padding-left: 30px;
      padding-right: 30px;
      text-align: left;
    }
  }
  &_title {
    font-size: 36px;
    color: $white;
  }
}
.close_button {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 23px;
  right: 16px;
  background-color: transparent;
  padding: 0;
  border: none;
  @include media(lg) {
    right: 30px;
    height: 36px;
    width: 36px;
  }
  svg {
    width: 100%;
  }
}
</style>
