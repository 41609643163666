import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import EventCategory from "../views/EventCategory.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/index",
    name: "Home",
    component: Home,
  },
  {
    path: "/eventCategory",
    name: "EventCategory",
    component: EventCategory,
  },
  {
    path: "/",
    redirect: {
      name: "Home",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
