<template>
  <div class="Header">
    <div class="website_title">
      <div class="slogan">
        <div>
          <h2>會說話</h2>
          <h2>會做事</h2>
        </div>
        <div class="legislator">
          <p>台灣基進立法委員</p>
          <h1>陳柏惟</h1>
        </div>
        <div class="title_decoration">
          <img
            src="@/assets/image/pic/title-decoration.png"
            alt="title-decoration"
          />
        </div>
      </div>
      <div class="header_people">
        <img src="@/assets/image/pic/people-01.png" alt="" />
      </div>
    </div>
    <div class="work_info">
      <time class="work_info_content">
        {{ inauguralDay }}
      </time>
      <span class="work_info_content">立院質詢率 100%</span>
    </div>
    <div class="political_achievement_figure">
      <div
        class="political_achievement proposal"
        @click="showAchievement('proposal')"
        v-scroll-to="'.Header'"
      >
        <div class="more_info">
          <span>詳細內容</span>
          <div class="more_info_icon">
            <arrowIconRed />
          </div>
        </div>
        <div class="more_info_hover">
          <span>詳細內容</span>
          <div class="more_info_icon">
            <arrowIcon />
          </div>
        </div>
        <div class="political_achievement_item">
          <p class="political_achievement_value">
            <animated-number
              :formatValue="countdownValue"
              :value="homeLength['法律主提案']"
              :duration="3000"
            />
          </p>
          <p class="political_achievement_name">法律主提案</p>
        </div>
        <div class="political_achievement_item" style="display: inline-block">
          <p class="political_achievement_value">
            <animated-number
              :formatValue="countdownValue"
              :value="homeLength['法律共同提案']"
              :duration="3000"
            />
          </p>
          <p class="political_achievement_name">法律共同提案</p>
        </div>
        <div class="political_achievement_item" style="display: inline-block">
          <p class="political_achievement_value">
            <animated-number
              :formatValue="countdownValue"
              :value="homeLength['書面質詢']"
              :duration="3000"
            />
          </p>
          <p class="political_achievement_name">書面質詢</p>
        </div>
        <div class="political_achievement_item" style="display: inline-block">
          <p class="political_achievement_value">
            <animated-number
              :formatValue="countdownValue"
              :value="homeLength['口頭質詢']"
              :duration="3000"
            />
          </p>
          <p class="political_achievement_name">口頭質詢</p>
        </div>
        <div class="political_achievement_item" style="display: inline-block">
          <p class="political_achievement_value">
            <animated-number
              :formatValue="countdownValue"
              :value="homeLength['其他國會發言']"
              :duration="3000"
            />
          </p>
          <p class="political_achievement_name">其他國會發言</p>
        </div>
      </div>

      <div
        class="political_achievement petition"
        @click="showAchievement('petition')"
        v-scroll-to="'.Header'"
      >
        <div class="more_info">
          <span>詳細內容</span>
          <div class="more_info_icon">
            <arrowIconRed />
          </div>
        </div>
        <div class="more_info_hover">
          <span>詳細內容</span>
          <div class="more_info_icon">
            <arrowIcon />
          </div>
        </div>
        <div class="political_achievement_item">
          <p class="political_achievement_value">
            <animated-number
              :formatValue="countdownValue"
              :value="homeLength['選服陳情案件']"
              :duration="3000"
            />
          </p>
          <p class="political_achievement_name">選服陳情案件</p>
        </div>
      </div>
      <div
        class="political_achievement survey"
        @click="showAchievement('survey')"
        v-scroll-to="'.Header'"
      >
        <div class="more_info">
          <span>詳細內容</span>
          <div class="more_info_icon">
            <arrowIconRed />
          </div>
        </div>
        <div class="more_info_hover">
          <span>詳細內容</span>
          <div class="more_info_icon">
            <arrowIcon />
          </div>
        </div>
        <div class="political_achievement_item">
          <p class="political_achievement_value">
            <animated-number
              :formatValue="countdownValue"
              :value="homeLength['會勘及地方建設(一覽)']"
              :duration="3000"
            />
          </p>
          <p class="political_achievement_name">會勘及地方建設</p>
        </div>
      </div>
      <div
        class="political_achievement press_conference"
        @click="showAchievement('pressConference')"
        v-scroll-to="'.Header'"
      >
        <div class="more_info">
          <span>詳細內容</span>
          <div class="more_info_icon">
            <arrowIconRed />
          </div>
        </div>
        <div class="more_info_hover">
          <span>詳細內容</span>
          <div class="more_info_icon">
            <arrowIcon />
          </div>
        </div>
        <div class="political_achievement_item">
          <p class="political_achievement_value">
            <animated-number
              :formatValue="countdownValue"
              :value="homeLength['全國活動']"
              :duration="3000"
            />
          </p>
          <p class="political_achievement_name">全國性活動</p>
        </div>
      </div>
      <div
        class="political_achievement welfare"
        @click="showAchievement('welfare')"
        v-scroll-to="'.Header'"
      >
        <div class="more_info">
          <span>詳細內容</span>
          <div class="more_info_icon">
            <arrowIconRed />
          </div>
        </div>
        <div class="more_info_hover">
          <span>詳細內容</span>
          <div class="more_info_icon">
            <arrowIcon />
          </div>
        </div>
        <div class="political_achievement_item">
          <p class="political_achievement_value">
            <animated-number
              :formatValue="countdownValue"
              :value="homeLength['地方公益活動']"
              :duration="3000"
            />
          </p>
          <p class="political_achievement_name">地方公益活動</p>
        </div>
      </div>
    </div>

    <ProposalResult
      :displayDefaultValue="achievementType === 'proposal'"
      v-if="achievementType === 'proposal'"
      @closeDetailModal="closeDetailModalHandler"
    >
    </ProposalResult>
    <PetitionCase
      :displayDefaultValue="achievementType === 'petition'"
      v-if="achievementType === 'petition'"
      @closeDetailModal="closeDetailModalHandler"
    >
    </PetitionCase>
    <ChariableActivity
      :displayDefaultValue="achievementType === 'welfare'"
      v-if="achievementType === 'welfare'"
      @closeDetailModal="closeDetailModalHandler"
    >
    </ChariableActivity>
    <PressConference
      :displayDefaultValue="achievementType === 'pressConference'"
      v-if="achievementType === 'pressConference'"
      @closeDetailModal="closeDetailModalHandler"
    />
    <Survey
      :displayDefaultValue="achievementType === 'survey'"
      v-if="achievementType === 'survey'"
      @closeDetailModal="closeDetailModalHandler"
    >
    </Survey>
  </div>
</template>

<script>
// package
import dayjs from "dayjs";

// component
import ProposalResult from "@/components/Popover/ProposalResult/ProposalResult.vue";
import ChariableActivity from "@/components/Popover/CharitableActivity.vue";
import PetitionCase from "@/components/Popover/PetitionCase.vue";
import PressConference from "@/components/Popover/PressConference.vue";
import Survey from "@/components/Popover/Survey/Survey.vue";

// svg loader
import arrowIcon from "@/assets/image/icon/arrow.svg";
import arrowIconRed from "@/assets/image/icon/arrow-red.svg";

export default {
  name: "Header",
  components: {
    ProposalResult,
    ChariableActivity,
    PetitionCase,
    PressConference,
    Survey,
    arrowIcon,
    arrowIconRed,
  },
  data() {
    return {
      inauguralDay: "",
      achievementType: null,
    };
  },
  computed: {
    homeLength() {
      return this.$store.getters.length_all;
    },
  },
  created() {
    this.inauguralDay = this.getInauguralDay();

    this.$store.dispatch("fetchDataLength");
  },
  methods: {
    getInauguralDay() {
      const inauguralDay = dayjs("2020-02-01 00:00:00");
      const now = dayjs("2021-10-18 23:59:59");

      let dayDiff = now.diff(inauguralDay, "day");
      let hoursDiff = now.format("HH");
      let minDiff = now.format("mm");
      let secDiff = now.format("ss");

      return `上任第 ${dayDiff} 天  ${hoursDiff}時 ${minDiff} 分 ${secDiff}秒`;
    },
    countdownValue(value) {
      return Math.floor(value);
    },
    showAchievement(achievementType) {
      this.achievementType = achievementType;
      this.displayDefaultValue = !this.displayDefaultValue;
    },
    closeDetailModalHandler() {
      this.achievementType = null;
      document.body.classList.remove("modal-open");
    },
  },
};
</script>
<style lang="scss" scooped>
.Header {
  max-width: 1440px;
  margin: 0 auto;
  padding: 50px 16px 25px;
  background: url("~@/assets/image/pic/logo.png") no-repeat -127px -100px;
  position: relative;
  @include media(md) {
    padding: 72px 100px 25px;
  }
  @include media(lg) {
    padding: 72px 60px 25px;
    background-size: 488px 488px;
    background-position-y: -160px;
  }
  @include media(xl) {
    padding: 72px 140px 25px;
  }
}

.website_title {
  margin-bottom: 30px;
  text-align: center;
  @include media(md) {
    min-height: 400px;
    display: flex;
    margin: 0;
  }
  @include media(lg) {
    min-height: unset;
    margin-bottom: 20px;
    flex-direction: column;
  }
  @include media(lg) {
    text-align: left;
  }
  .slogan {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    @include media(md) {
      flex: 2 0 0;
    }
    @include media(md) {
      display: block;
    }
    h1 {
      font-size: 65px;
      font-weight: $block;
      color: $red-600;
      line-height: 1.2;
      @include media(lg) {
        list-style: 1.3;
      }
    }
    h2 {
      font-size: 65px;
      font-weight: $block;
      color: $red-600;
      line-height: 1.2;
      @include media(lg) {
        font-size: 90px;
        margin-right: 10px;
        display: inline-block;
        line-height: 1.3;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    p {
      margin: 0;
      font-size: 24px;
      font-weight: $bold;
      color: $red-600;
      line-height: 1;
    }
    div {
      margin-bottom: 10px;
    }
  }
  .legislator {
    @include media(lg) {
      display: inline-block;
      margin-right: 66px;
      line-height: 100px;
      vertical-align: middle;
      img {
        width: 100%;
      }
    }
  }
  .title_decoration {
    display: none;
    @include media(lg) {
      width: 167px;
      height: 100px;
      display: inline-block;
      line-height: 100px;
      vertical-align: middle;
      img {
        width: 100%;
      }
    }
  }
}

.work_info {
  margin-bottom: 20px;
  position: absolute;
  top: 595px;
  left: 16px;
  right: 16px;
  &:last-child {
    margin: 0;
  }
  @include media(md) {
    top: 360px;
    left: 100px;
    right: 100px;
  }
  @include media(lg) {
    top: 25px;
    right: 25px;
    left: unset;
  }
  &_content {
    padding: 5px 0;
    margin-bottom: 8px;
    display: block;
    background-color: $red-600;
    font-weight: $block;
    color: $white;
    text-align: center;
    &:last-child {
      margin: 0;
    }
    @include media(lg) {
      margin-right: 16px;
      padding: 7px 21px;
      display: inline-block;
      font-size: 20px;
      letter-spacing: 0.02rem;
    }
  }
}
.political_achievement {
  height: 200px;
  padding: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  @include media(xl) {
    margin-bottom: 0;
    padding: 35px 30px;
  }
  &_figure {
    @include media(lg) {
      display: grid;
      grid-template-columns: 20% 10% 21% 10% 20% 10%;
      grid-template-rows: 436px 298px 298px;
      grid-gap: 20px;
    }
  }
  &_item {
    margin-right: 23px;
    &:last-child {
      margin-right: 0;
    }
  }
  &_value {
    margin: 0;
    font-size: 50px;
    font-weight: $block;
    color: $red-600;
    line-height: 1;
    @include media(lg) {
      font-size: 80px;
    }
  }
  &_name {
    margin: 0;
    font-weight: $block;
    color: $red-600;
    @include media(lg) {
      font-size: 24px;
    }
  }
}

.header_people {
  width: 340px;
  height: 370px;
  display: block;
  margin: -25px auto 0;
  img {
    width: 100%;
    object-fit: cover;
  }
  @include media(lg) {
    width: 49%;
    height: 55%;
    margin: unset;
    display: block;
    position: absolute;
    top: 22.5%;
    left: 50.5%;
    z-index: 2;
    img {
      height: 100%;
    }
  }
  @include media(xl) {
    width: 700px;
    height: 950px;
    display: block;
    position: absolute;
    top: 165px;
    left: unset;
    right: 50px;
  }
}

.more_info {
  @include moreInfo;
  span {
    color: $red-600;
  }
  &_hover {
    @include moreInfo;
    span {
      display: none;
    }
    .more_info_icon {
      opacity: 0;
      transform: rotate(-90deg);
    }
  }
}

.proposal {
  position: relative;
  background: url("~@/assets/image/pic/proposal-bg.png") no-repeat right bottom
    $gray-100;
  background-size: 200px;
  display: grid;
  grid-template-columns: 3fr 4fr;
  height: 300px;

  @include media(lg) {
    width: 450px;
    height: auto;
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 2;
    order: 1;
    background-size: 350px;
  }
  @include media(xl) {
    width: 612px;
  }
}

.proposal:hover {
  background-color: $red-600;
  background-image: none;
}

.proposal:hover .political_achievement_item .political_achievement_value {
  color: $white;
}

.proposal:hover .political_achievement_item .political_achievement_name {
  color: $white;
}

.proposal:hover .more_info {
  display: none;
}

.proposal:hover .more_info_hover {
  display: flex;
  span {
    display: block;
  }
  .more_info_icon {
    opacity: 1;
    transform: rotate(0deg);
    transition: transform 1s;
  }
}

.petition {
  background: url("~@/assets/image/pic/petition-bg.png") no-repeat right bottom
    $gray-100;
  background-size: 150px;
  position: relative;
  @include media(lg) {
    height: auto;
    grid-column-start: 1;
    grid-column-end: 3;
    order: 2;
    background-size: 200px;
  }
}

.petition:hover {
  background-color: $red-600;
  background-image: none;
}

.petition:hover .political_achievement_item .political_achievement_value {
  color: $white;
}

.petition:hover .political_achievement_item .political_achievement_name {
  color: $white;
}

.petition:hover .more_info {
  display: none;
}

.petition:hover .more_info_hover {
  display: flex;
  span {
    display: block;
  }
  .more_info_icon {
    opacity: 1;
    transform: rotate(0deg);
    transition: transform 1s;
  }
}

.survey {
  background: url("~@/assets/image/pic/survey-bg.png") no-repeat right bottom
    $gray-100;
  position: relative;
  @include media(lg) {
    height: auto;
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 4;
    order: 4;
    background: url("~@/assets/image/pic/survey-bg-lg.png") no-repeat right
      bottom $gray-100;
    background-size: 70%;
  }
}

.survey:hover {
  background-color: $red-600;
  background-image: none;
}

.survey:hover .political_achievement_item .political_achievement_value {
  color: $white;
}

.survey:hover .political_achievement_item .political_achievement_name {
  color: $white;
}

.survey:hover .more_info {
  display: none;
}

.survey:hover .more_info_hover {
  display: flex;
  span {
    display: block;
  }
  .more_info_icon {
    opacity: 1;
    transform: rotate(0deg);
    transition: transform 1s;
  }
}

.press_conference {
  background: url("~@/assets/image/pic/press-conference-bg.png") no-repeat right
    bottom $gray-100;
  position: relative;
  @include media(lg) {
    height: auto;
    grid-column-start: 1;
    grid-column-end: 3;
    order: 3;
    text-align: center;
    background: url("~@/assets/image/pic/press-conference-bg-lg.png") no-repeat
      center bottom $gray-100;
    background-size: 100%;
  }
}

.press_conference:hover {
  background-color: $red-600;
  background-image: none;
}

.press_conference:hover
  .political_achievement_item
  .political_achievement_value {
  color: $white;
}

.press_conference:hover
  .political_achievement_item
  .political_achievement_name {
  color: $white;
}
.press_conference:hover .more_info {
  display: none;
}

.press_conference:hover .more_info_hover {
  display: flex;
  span {
    display: block;
  }
  .more_info_icon {
    opacity: 1;
    transform: rotate(0deg);
    transition: transform 1s;
  }
}

@include media(lg) {
  .press_conference {
    &:hover {
      .more_info_hover {
        right: 50%;
        transform: translateX(50%);
      }
    }
    .more_info {
      right: 50%;
      transform: translateX(50%);
    }
  }
}

.welfare {
  background: url("~@/assets/image/pic/welfare-bg.png") no-repeat right bottom
    $gray-100;
  background-size: 200px;
  position: relative;
  @include media(lg) {
    height: auto;
    grid-column-start: 5;
    grid-column-end: 7;
    order: 5;
    background-size: 300px;
  }
}

.welfare:hover {
  background-color: $red-600;
  background-image: none;
}

.welfare:hover .political_achievement_item .political_achievement_value {
  color: $white;
}

.welfare:hover .political_achievement_item .political_achievement_name {
  color: $white;
}

.welfare:hover .more_info {
  display: none;
}

.welfare:hover .more_info_hover {
  display: flex;
  span {
    display: block;
  }
  .more_info_icon {
    opacity: 1;
    transform: rotate(0deg);
    transition: transform 1s;
  }
}
</style>
