<template>
  <div class="PressConference">
    <ShellHeader title="全國性活動" @click="$emit('closeDetailModal')" />
    <SideNavShell
      :sortItems="sortItems"
      :dataType="dataType"
      @dataTypeChanged="dataType = $event"
    >
      <template #list="{ item }">
        <span class="data_name">{{ item.type }}</span>
        <animated-number
          :formatValue="($event) => Math.floor($event)"
          :value="length[item.type]"
          :duration="1500"
          class="data_length"
        />
      </template>
      <template #default>
        <div ref="conferenceWrap">
          <SelectContainer>
            <BaseSelect
              v-model="secondSortSelected"
              :options="secondOptions"
              :selectRef="secondRef"
              @input="backToTop"
            />
          </SelectContainer>
          <div
            class="conference_content"
            v-for="(row, index) in secondSortedData"
            :key="index"
          >
            <BaseInfo>
              <div class="case_name">{{ index + 1 }}.{{ row.名稱 }}</div>
              <div class="case_info">
                <div class="case_time">
                  日期：<time>{{ row.日期 }}</time>
                </div>
                <div class="case_address">活動性質：{{ row.性質 }}</div>
              </div>
            </BaseInfo>
            <div v-if="row.內容概要" class="case_content">
              <p class="case_subtitle">內容概要：</p>
              <p>{{ row.內容概要 }}</p>
            </div>
            <div v-if="isShowLink(row)" class="case_sns">
              <span class="case_subtitle">相關連結：</span>
              <a :href="row.相關連結1" target="_blank">
                {{ row.標題1 || row.相關連結1 }}
              </a>
              <a :href="row.相關連結2" target="_blank">
                {{ row.標題2 || row.相關連結2 }}
              </a>
              <a :href="row.相關連結3" target="_blank">
                {{ row.標題3 || row.相關連結3 }}
              </a>
              <a :href="row.相關連結4" target="_blank">
                {{ row.標題4 || row.相關連結4 }}
              </a>
              <a :href="row.相關連結5" target="_blank">
                {{ row.標題5 || row.相關連結5 }}
              </a>
              <a :href="row.相關連結6" target="_blank">
                {{ row.標題6 || row.相關連結6 }}
              </a>
            </div>
            <div v-if="row.照片" class="case_image">
              <p class="case_subtitle">相關照片：</p>
              <Lightbox :items="row.照片 && row.照片.map((i) => i.url)" />
            </div>
          </div>
        </div>
      </template>
    </SideNavShell>
  </div>
</template>

<script>
import airtable from "@/mixins/airtable.vue";

import Lightbox from "@/components/Lightbox.vue";
import ShellHeader from "@/components/Popover/ShellHeader.vue";
import SideNavShell from "./SideNavShell.vue";
import secondSort from "@/mixins/secondSort.vue";
import popupVue from "../../mixins/popup.vue";
import BaseSelect from "./BaseSelect.vue";
import SelectContainer from "./SelectContainer.vue";
import BaseInfo from "@/components/Popover/BaseInfo.vue";

export default {
  name: "PressConference",
  mixins: [airtable, secondSort, popupVue],
  components: {
    Lightbox,
    ShellHeader,
    SideNavShell,
    BaseSelect,
    SelectContainer,
    BaseInfo,
  },
  data() {
    return {
      dataType: "全國性活動",
    };
  },
  computed: {
    secondRef() {
      return "性質";
    },
    sortItems() {
      return [
        {
          type: "代表性全國活動",
          text: `${"代表性全國活動"}(${this.length["代表性全國活動"]})`,
        },
        {
          type: "全國性活動",
          text: `${"全國性活動"}(${this.length["全國性活動"]})`,
        },
      ];
    },
    length() {
      return this.$store.getters.length_all;
    },
    orderSecondOptions() {
      return [
        "考察",
        "演講",
        "公聽會",
        "協調會",
        "座談會",
        "記者會",
        "公務考察",
        "參訪接見",
        "活動出席",
        "活動聲援",
        "國會外交",
        "部會報告",
        "陳情接見",
        "議題研討",
        "團體來訪",
        "團體拜訪",
      ];
    },
  },
  watch: {
    dataType(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.list = [];
      this.getData(newValue, {
        sort: [{ field: "日期", direction: "desc" }],
      });
    },
  },
  props: {
    displayDefaultValue: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    dataTypeChangeHandler(event) {
      if (this.dataType === event) return;
      this.list = [];
      this.dataType = event;
      this.getData(event, {
        sort: [{ field: "日期", direction: "desc" }],
      });
    },
    isShowLink(row) {
      return Object.keys(row).find((keyName) => keyName.includes("相關"));
    },
    backToTop() {
      this.$refs.conferenceWrap.scroll(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.PressConference {
  @include popup;
}

.conference_content {
  padding: 16px;
  border-bottom: solid 20px $red-600;
  @include media(md) {
    padding: 50px 55px;
  }
}

.category {
  &_name {
    font-weight: $bold;
  }
  &_content {
    margin: 0;
  }
}

.case {
  &_content {
    p {
      margin: 0 0 8px;
      white-space: pre-wrap;
    }
  }
  &_subtitle {
    font-weight: 600;
  }
  &_sns {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    a {
      width: fit-content;
      text-decoration: underline;
    }
  }
}
</style>
