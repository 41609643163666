<template>
  <div class="home">
    <header>
      <Header />
    </header>
    <section class="activity">
      <figure class="activity_statistics">
        <div class="activity_statistics_title">
          <h3>做台灣的工</h3>
          <h3>走台灣的路</h3>
        </div>
        <p class="activity_statistics_content">
          我與團隊深信，將服務項目開誠布公是最好的檢驗，<br />
          反對方不滿這十幾項，我們要跟你說好幾千項，<br />
          請您幫我一個忙，分享3Q愛台灣，<br />
          告訴自己的朋友與鄉親，這是一個會說話，會做事的好團隊。
        </p>
      </figure>
    </section>
    <section class="introduction">
      <div class="introduction_wrap">
        <div class="introduction_image">
          <img src="@/assets/image/pic/people-02.png" alt="" />
        </div>
        <div class="introduction_content">
          <h3>盡忠職守，問心無愧</h3>
          <p>
            無論您喜歡或討厭我，都邀請您出來投票，<br />
            我們支持的是台灣、是民主，<br />
            讓我們用選票說出自己的聲音，讓台灣再贏一次！
          </p>
          <p>我是台灣基進的立法委員-陳柏惟。大風大浪，一起前行，3Q！</p>
        </div>
        <div class="introduction_info">
          <span class="office">
            國會研究室：
            <a href="tel:(02)2358-8156">(02)2358-8156</a>
            <span>100-224 臺北市中正區濟南路一段3-1號0708室</span>
          </span>
          <span class="office">
            烏日服務處：
            <a href="tel:(04)2337-7383">(04)2337-7383</a>
            <span>414-603 臺中市烏日區中山路一段525號</span>
          </span>
          <span class="office">
            沙鹿服務處：
            <a href="tel:(04)2662-0913">(04)2662-0913</a>
            <span>433-107 臺中市沙鹿區中山路537號</span>
          </span>
          <span class="office">
            霧峰服務處：
            <a href="tel:(04)2330-5663">(04)2330-5663</a>
            <span>413-007 臺中市霧峰區文化巷57號</span>
          </span>
          <span class="office">
            大肚服務處：
            <a href="tel:(04)2699-8903">(04)2699-8903</a>
            <span>432-011 臺中市大肚區自由路148號</span>
          </span>
          <span class="office">
            龍井中央路服務處：
            <a href="tel:(04)2639-1163">(04)2639-1163</a>
            <span>434-008 臺中市龍井區中央路三段169號</span>
          </span>
        </div>
        <div class="introduction_media">
          <div class="fan_page">
            <span>加入：</span>
            <a href="https://lin.ee/B4TeoDx" target="blank">
              <lineIcon />
            </a>
            <a href="https://www.facebook.com/3Q.PehUi" target="blank">
              <facebookIcon />
            </a>
          </div>
          <div class="share_line">
            <span>分享：</span>
            <ShareNetwork
              network="Line"
              url="https://3qi.tw/index"
              title="3Q 愛台灣"
              description="盡忠職守、全力以赴，篳路藍縷、無怨無悔，齊心防疫、台灣平安，3Q！"
              :popup="{ width: 600, height: 500 }"
            >
              <lineIcon />
            </ShareNetwork>
            <ShareNetwork
              network="Facebook"
              url="https://3qi.tw/index"
              title="3Q 愛台灣"
              description="盡忠職守、全力以赴，篳路藍縷、無怨無悔，齊心防疫、台灣平安，3Q！"
              :popup="{ width: 600, height: 500 }"
            >
              <facebookIcon />
            </ShareNetwork>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// component
import Header from "@/components/Header.vue";

//svg
import lineIcon from "@/assets/image/icon/line-icon.svg";
import facebookIcon from "@/assets/image/icon/facebook-icon.svg";
export default {
  name: "Home",
  components: {
    Header,
    lineIcon,
    facebookIcon,
  },
  methods: {
    countdownValue(value) {
      return Math.floor(value);
    },
    trackGA() {},
  },
};
</script>

<style lang="scss" scoped>
.activity_statistics {
  padding: 30px;
  background-color: $red-600;
  @include media(md) {
    padding: 80px 0;
  }
  &_wrap {
    width: 900px;
    margin: 0 auto;
  }
  &_title {
    margin-bottom: 20px;
    text-align: center;
    h3 {
      font-size: 40px;
      color: $white;
      line-height: 54px;
      @include media(md) {
        margin-right: 16px;
        display: inline;
        font-size: 60px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  &_content {
    margin: 0 auto;
    color: $white;
    text-align: justify;
    letter-spacing: 2px;
    @include media(md) {
      width: 600px;
      font-size: 20px;
    }
    a {
      color: $white;
      text-decoration: underline;
    }
  }
}

.introduction {
  background-color: $gray-100;
  &_wrap {
    max-width: 1440px;
    margin: 0 auto;
    padding: 30px;
    position: relative;
    @include media(md) {
      padding: 50px;
    }
    @include media(xl) {
      padding: 42px 160px;
    }
  }
  &_image {
    display: none;
    @include media(lg) {
      width: 450px;
      height: 700px;
      display: block;
      position: absolute;
      right: 55%;
      bottom: 0;
      z-index: 2;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    @include media(xl) {
      width: 450px;
      height: 700px;
      right: 65%;
    }
  }
  &_content {
    text-align: justify;
    @include media(md) {
      width: 600px;
      margin: 0 auto;
    }
    @include media(lg) {
      width: 50%;
      margin: 0 0 0 auto;
    }
    @include media(xl) {
      width: 600px;
    }
    h3 {
      @include media(md) {
        font-size: 24px;
      }
    }
    p {
      @include media(md) {
        font-size: 18px;
      }
    }
  }
  &_info {
    @include media(md) {
      width: 600px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 16px;
    }
    @include media(lg) {
      width: 50%;
      margin: 0 0 0 auto;
    }
    @include media(xl) {
      width: 600px;
    }
    .office {
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
      font-weight: 600;
      a {
        color: $red-600;
        text-decoration: underline;
        font-weight: 400;
      }
      span {
        font-weight: 400;
      }
    }
  }
  &_media {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    color: $red-600;
    @include media(md) {
      width: 600px;
      margin: 0 auto;
      grid-column-gap: 16px;
    }
    @include media(lg) {
      width: 50%;
      margin: 0 0 0 auto;
    }
    @include media(xl) {
      width: 600px;
    }
    .fan_page {
      display: flex;
      align-items: center;
      gap: 10px;
      span {
        font-weight: 600;
      }
      a {
        width: 36px;
        height: 36px;
        display: block;
      }
    }
    .share_line {
      display: flex;
      align-items: center;
      gap: 10px;
      span {
        font-weight: 600;
      }
      a {
        width: 36px;
        height: 36px;
        display: block;
      }
    }
  }
}
</style>
