import Vue from "vue";
import App from "./App.vue";
import router from "./router/IndexRouter";
import VueCookies from "vue-cookies";
import axios from "axios";
import VueAxios from "vue-axios";
import base from "@/plugins/airtable.js";
import store from "@/store/index.js";

Vue.prototype.$base = base;

Vue.use(VueAxios, axios);

Vue.use(VueCookies);
// v-calender
// import "@/plugins/v-calender";
import VCalendar from "v-calendar";
Vue.use(VCalendar);

// bootstrapVue
import { BootstrapVue } from "bootstrap-vue";
Vue.use(BootstrapVue);

// VueScrollTo
import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

// AnimatedNumber
import AnimatedNumber from "animated-number-vue";
Vue.component("animated-number", AnimatedNumber);

// VueSocialSharing
import VueSocialSharing from "vue-social-sharing";
Vue.use(VueSocialSharing);

// Vue-gtag
import VueGtag from "vue-gtag";
Vue.use(
  VueGtag,
  {
    config: { id: "UA-202309059-1" },
  },
  router
);

// Sass
import "@/assets/scss/main.scss";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
