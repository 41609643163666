<template>
  <div class="BaseInfo">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BaseInfo",
};
</script>

<style lang="scss" scoped>
.case {
  &_name {
    margin: 0 0 16px 0;
    font-size: 17px;
    font-weight: $bold;
    letter-spacing: 0.02em;
    white-space: pre-line;
  }
  &_info {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    color: $gray-600;
  }
}
</style>
