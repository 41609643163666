<template>
  <div class="survey_wrap">
    <SelectContainer>
      <BaseSelect
        v-model="secondSortSelected"
        :options="secondOptions"
        :selectRef="secondRef"
      />
    </SelectContainer>
    <div
      class="survey_content"
      v-for="(row, index) in secondSortedData"
      :key="index"
    >
      <BaseInfo>
        <div class="case_name">{{ index + 1 }}.{{ row.建設標題 }}</div>
        <div class="case_info">
          <span class="case_address">
            陳情來源/區域：{{ row["陳情來源／區域"] }}</span
          >
          <span class="case_participants"> 主責單位：{{ row.主責單位 }} </span>
        </div>
      </BaseInfo>
      <div class="case_category">
        <span class="case_category_name">案件內容：</span>
        <p class="case_category_content">
          {{ row.案件內容 }}
        </p>
        <div v-if="isShowLink(row)" class="case_category_link">
          <span class="case_category_name">相關連結：</span>
          <a :href="row.相關連結" target="_blank">{{ row.標題 }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import airtable from "@/mixins/airtable.vue";
import secondSort from "@/mixins/secondSort.vue";
import BaseSelect from "@/components/Popover/BaseSelect.vue";
import SelectContainer from "../SelectContainer.vue";
import BaseInfo from "@/components/Popover/BaseInfo.vue";

export default {
  name: "RepresentativeSurvey",
  mixins: [airtable, secondSort],
  components: {
    BaseSelect,
    SelectContainer,
    BaseInfo,
  },
  computed: {
    secondRef() {
      return "陳情來源／區域";
    },
    orderSecondOptions() {
      return ["霧峰", "沙鹿", "大肚", "烏日", "龍井", "其他"];
    },
  },
  mounted() {
    this.getData("代表性地方建設");
  },
  methods: {
    linkHandler(link) {
      if (!link || typeof link !== "string") return [];
      const stringArray = link.split("\n").filter((i) => i !== "");
      if (stringArray.length === 1) return [{ sourceName: "", urls: [link] }];

      let index = -1;
      const result = [];
      stringArray.forEach((item) => {
        if (!item.startsWith("https://")) {
          if (index === -1) index = 0;
          else index++;
          result.push({ sourceName: item });
        } else {
          if (result[index]) {
            if (!Array.isArray(result[index].urls)) {
              result[index].urls = [item];
            } else {
              result[index].urls.push(item);
            }
          } else {
            console.log("something wrong");
          }
        }
      });
      return result;
    },
    isShowLink(row) {
      return Object.keys(row).find((keyName) => keyName.includes("相關"));
    },
  },
};
</script>

<style lang="scss" scoped>
.survey {
  @include popup;
  &_content {
    padding: 16px;
    border-bottom: solid 20px $red-600;
    @include media(md) {
      padding: 50px 55px;
    }
  }
}

.case {
  &_category {
    &_name {
      font-weight: 600;
    }
    &_content {
      margin: 0;
      white-space: pre-line;
    }
    &_link {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
