<template>
  <div class="survey_wrap">
    <SelectContainer>
      <BaseSelect
        v-model="secondSortSelected"
        :options="secondOptions"
        :selectRef="secondRef"
        @input="backToTop"
      />
    </SelectContainer>
    <template v-for="(row, index) in secondSortedData">
      <div
        class="survey_content"
        :key="index"
        v-if="row && Object.keys(row).length !== 0"
      >
        <BaseInfo>
          <div class="case_name">{{ index + 1 }}.{{ row.內容 }}</div>
          <div class="case_info">
            <span class="case_address"> 會勘地點：{{ row.區域 }} </span>
            <span class="case_time">
              會勘日期：<time>{{ row.辦理日期 }}</time>
            </span>
            <span class="case_participants">
              參與單位：{{ row.參與單位 }}
            </span>
          </div>
        </BaseInfo>
        <div v-if="row.結論" class="case_content">
          <span class="case_subtitle">會議結論</span>
          <p>
            {{ row.結論 }}
          </p>
        </div>
        <div v-if="row.當天照片" class="case_image">
          <p class="case_subtitle">相關照片：</p>
          <Lightbox :items="pickPhotosHandler(row)" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import secondSort from "@/mixins/secondSort.vue";

import Lightbox from "@/components/Lightbox.vue";
import BaseSelect from "../BaseSelect.vue";
import SelectContainer from "../SelectContainer.vue";
import BaseInfo from "@/components/Popover/BaseInfo.vue";

export default {
  name: "SurveyResult",
  mixins: [secondSort],
  components: {
    Lightbox,
    BaseSelect,
    SelectContainer,
    BaseInfo,
  },
  props: {
    dataType: {
      type: String,
      required: true,
    },
    listData: {
      type: Array,
      required: true,
    },
  },
  computed: {
    secondRef() {
      return "性質";
    },
    orderSecondOptions() {
      // 資料裡面有"爭取地方建設"，不在 hackMD 裡
      return ["會勘", "協調會", "公聽會", "爭取地方發展", "爭取地方建設"];
    },
  },
  watch: {
    dataType(newValue, oldValue) {
      if (!oldValue) return;
      else if (newValue === oldValue) return;
      this.backToTop();
    },
  },
  methods: {
    pickPhotosHandler(row) {
      if (row.當天照片 && Array.isArray(row.當天照片)) {
        return row.當天照片.map((i) => i.url);
      } else if (row.結論與照片 && Array.isArray(row.結論與照片)) {
        return row.結論與照片.map((i) => i.url);
      } else return [];
    },
    backToTop() {
      this.$el.scroll(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.survey {
  &_wrap {
    flex: 2 0 0;
  }
  &_content {
    padding: 16px;
    border-bottom: solid 20px $red-600;
    @include media(md) {
      padding: 50px 55px;
    }
  }
}

.case {
  &_content {
    margin-bottom: 16px;
    p {
      margin: 4px 0 0 0;
      white-space: pre-line;
    }
  }
}
</style>
