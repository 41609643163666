<script>
export default {
  data: () => ({
    list: [],
    more: null,
  }),
  computed: {
    listData() {
      return this.list.map((record) => record.fields);
    },
  },
  methods: {
    youtube_parser(url) {
      var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return match && match[7].length == 11 ? match[7] : false;
    },
    getData(table, option = {}) {
      this.$base(table)
        .select({
          maxRecords: 1000,
          ...option,
        })
        .eachPage(
          (records, fetchNextPage) => {
            this.list = this.list.concat(records);
            if (option.pageSize && records.length >= option.pageSize) {
              this.more = fetchNextPage;
            }
            if (!option.pageSize) fetchNextPage();
          },
          (err) => {
            if (err) {
              console.error(err);
              return;
            }
          }
        );
    },
  },
};
</script>
