<template>
  <div>
    <div class="top">
      <span v-for="(type, index) of types" :key="index">
        {{ type.label }}
        <textarea v-model="type.value" cols="20" rows="5"></textarea>
      </span>
      <div>
        總共: {{ events.length }}, 已分類: {{ catsNum }}, 未分類:
        {{ events.length - catsNum }},
        <label> <input type="checkbox" v-model="hide" /> 隱藏已分類 </label>
      </div>
    </div>
    <div class="main">
      <div>
        <table>
          <tr>
            <th>類別</th>
            <th>行程</th>
            <th>時間</th>
          </tr>
          <tr
            v-for="(event, index) of events"
            :key="index"
            v-show="!hide || event.cats.length == 0"
          >
            <td>{{ event.cats }}</td>
            <td>{{ event.SUMMARY }}</td>
            <td>{{ event.DTSTART | dayFormat }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import events from "@/data/c1.json";
import dayjs from "dayjs";
import _ from "lodash";

export default {
  name: "EventCategory",
  data() {
    return {
      events: [],
      types: [
        {
          label: "國會行程",
          value: "",
        },
        {
          label: "地方行程",
          value: "",
        },
        {
          label: "鄉親拜訪",
          value: "",
        },
        {
          label: "媒體節目",
          value: "",
        },
        {
          label: "排除",
          value: "",
        },
      ],
      hide: false,
    };
  },
  computed: {
    catsNum() {
      return this.events.filter((event) => (event.cats ? event.cats.length : 0))
        .length;
    },
  },
  filters: {
    dayFormat(val) {
      return dayjs(val).format("YYYY-MM-DD");
    },
  },
  created() {
    this.events = events;
    let cookieTypes = this.$cookies.get("types");
    try {
      let parseTypes = JSON.parse(cookieTypes);
      if (typeof parseTypes === "object")
        this.types = _.unionBy(parseTypes, this.types, "label");
    } catch (e) {
      console.log("");
    }
  },
  methods: {
    getCategory(text) {
      let cats = [];
      this.types.forEach((type) => {
        if (
          _.some(_.filter(type.value.split("\n"), _.size), (el) =>
            _.includes(text, el)
          )
        ) {
          cats.push(type.label);
        }
      });

      //   this.events[index].cats = cats;
      return cats;
    },
  },
  watch: {
    types: {
      handler: function (types) {
        this.$cookies.set("types", JSON.stringify(types));
        this.events = this.events.map((event) => {
          event.cats = this.getCategory(event.SUMMARY);
          return event;
        });
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  height: calc(100vh - 150px);
  overflow: auto;
}
.top {
  height: 150px;
}
</style>
