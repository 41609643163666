<template>
  <div class="ProposalResult">
    <ShellHeader @click="$emit('closeDetailModal')">
      <div class="proposal_result_notice">
        <span>立院成果</span>
        <span>
          想知道更多問政成果嗎？歡迎至
          <a href="https://npl.ly.gov.tw">立法院國會圖書館</a>
          之「立委問政專輯」查看，3Q！
        </span>
      </div>
    </ShellHeader>
    <SideNavShell
      :sortItems="sortItems"
      :dataType="dataType"
      @dataTypeChanged="dataType = $event"
    >
      <template #list="{ item }">
        <span class="data_name">{{ item.type }}</span>
        <animated-number
          :formatValue="($event) => Math.floor($event)"
          :value="proposalCaseLength[item.type]"
          :duration="3000"
          class="data_length"
        />
      </template>
      <template #default>
        <ProposalCase v-if="dataType === '法律主提案'" />
        <ProposalJointCase v-if="dataType === '法律共同提案'" />
        <OralInterpellation v-if="dataType === '口頭質詢'" />
        <WrittenInquiry v-if="dataType === '書面質詢'" />
        <OtherCongressSpeeches v-if="dataType === '其他國會發言'" />
      </template>
    </SideNavShell>
  </div>
</template>

<script>
import ProposalCase from "./ProposalCase.vue";
import ProposalJointCase from "./ProposalJointCase.vue";
import OralInterpellation from "./OralInterpellation.vue";
import WrittenInquiry from "./WrittenInquiry.vue";
import OtherCongressSpeeches from "./OtherCongressSpeeches.vue";
import SideNavShell from "../SideNavShell.vue";
import ShellHeader from "../ShellHeader.vue";
import popupVue from "../../../mixins/popup.vue";

export default {
  name: "ProposalResult",
  components: {
    ProposalCase,
    ProposalJointCase,
    OralInterpellation,
    WrittenInquiry,
    OtherCongressSpeeches,
    ShellHeader,
    SideNavShell,
  },
  mixins: [popupVue],
  data() {
    return {
      dataType: "",
      proposalType: "法律主提案",
    };
  },
  computed: {
    sortItems() {
      return [
        {
          type: "法律主提案",
          text: `${"法律主提案"}(${this.proposalCaseLength["法律主提案"]})`,
        },
        {
          type: "法律共同提案",
          text: `${"法律共同提案"}(${this.proposalCaseLength["法律共同提案"]})`,
        },
        {
          type: "書面質詢",
          text: `${"書面質詢"}(${this.proposalCaseLength["書面質詢"]})`,
        },
        {
          type: "口頭質詢",
          text: `${"口頭質詢"}(${this.proposalCaseLength["口頭質詢"]})`,
        },
        {
          type: "其他國會發言",
          text: `${"其他國會發言"}(${this.proposalCaseLength["其他國會發言"]})`,
        },
      ];
    },
    proposalCaseLength() {
      return this.$store.getters.length_all;
    },
  },
  props: {
    displayDefaultValue: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.ProposalResult {
  @include popup;
}

.proposal_result {
  &_notice {
    display: flex;
    flex-direction: column;
    @include media(lg) {
      flex-direction: row;
      align-items: flex-end;
    }
    span:first-child {
      margin-bottom: 16px;
      font-size: 36px;
      @include media(lg) {
        font-size: 36px;
        margin: 0;
      }
    }
    span:last-child {
      font-size: 15px;
      line-height: 1.2;
      @include media(lg) {
        margin-left: 10px;
      }
    }
    a {
      font-size: 15px;
      color: $white;
      text-decoration: underline 2px;
    }
  }
}
</style>
