import Vue from "vue";
import Vuex from "vuex";
import base from "@/plugins/airtable.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    list: [],
  },
  getters: {
    _map(state) {
      return state.list.reduce((accumulator, item) => {
        accumulator[item.名稱] = Number(item.資料數量);
        return accumulator;
      }, {});
    },
    length_PressConferenceSum(_state, getters) {
      return getters._map["全國性活動"] + getters._map["代表性全國活動"];
    },
    length_all(_state, getters) {
      return {
        ...getters._map,
        全國活動: getters.length_PressConferenceSum,
      };
    },
  },
  mutations: {
    _refreshDataLength(state, payload) {
      state.list = payload;
    },
  },
  actions: {
    fetchDataLength({ commit }) {
      base("各資料數量")
        .select()
        .all()
        .then((results) => {
          commit(
            "_refreshDataLength",
            results.map((i) => i.fields)
          );
        });
    },
  },
});
