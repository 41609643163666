<template>
  <div class="OtherCongressSpeeches">
    <div
      class="proposal_result_content"
      v-for="(row, index) in listData"
      :key="index"
    >
      <div
        class="case_video"
        v-if="row['YT連結'] && row['YT連結'].includes('youtube')"
      >
        <iframe
          id="ytplayer"
          type="text/html"
          width="100%"
          height="100%"
          :src="
            'https://www.youtube.com/embed/' + youtube_parser(row['YT連結'])
          "
          frameborder="0"
        ></iframe>
      </div>
      <figure>
        <BaseInfo>
          <p class="case_name">{{ index + 1 }}.{{ row["3Q問政"] }}</p>
          <div class="case_info">
            <span name="case_date"> 時間：{{ row.時間 }} </span>
            <span name="case_location"> 主辦單位：{{ row.主辦單位 }} </span>
          </div>
        </BaseInfo>
        <div class="case_content">
          <p>{{ row.事由 }}</p>
        </div>
      </figure>
      <div v-if="row['YT連結'] && !row['YT連結'].includes('youtube')">
        <a target="_blank" :href="row['YT連結']">資料連結</a>
      </div>
    </div>
    <div v-if="more" class="btn-more" @click="more()">讀取更多</div>
    <div class="empty-space"></div>
  </div>
</template>

<script>
import airtable from "@/mixins/airtable.vue";
import BaseInfo from "@/components/Popover/BaseInfo.vue";
export default {
  name: "OralInterpellation",
  components: {
    BaseInfo,
  },
  mixins: [airtable],
  mounted() {
    this.getData("其他國會發言", {
      pageSize: 6,
      sort: [{ field: "時間", direction: "desc" }],
    });
  },
};
</script>

<style lang="scss" scoped>
.proposal_result_content {
  padding: 16px;
  border-bottom: solid 20px $red-600;
  @include media(md) {
    padding: 50px 55px;
    display: flex;
    gap: 16px;
  }
  figure {
    flex: 1 0 0;
  }
}
.video_button {
  width: 50px;
  height: 50px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // background-color: $white;
}
</style>
