<template>
  <div class="CharitableActivity">
    <ShellHeader title="地方公益活動" @click="$emit('closeDetailModal')" />
    <SideNavShell
      :sortItems="sortItems"
      :dataType="dataType"
      @dataTypeChanged="dataType = $event"
    >
      <template #list="{ item }">
        <span class="data_name">{{ item.type }}</span>
        <animated-number
          :formatValue="($event) => Math.floor($event)"
          :value="
            firstSortedData[item.type] && firstSortedData[item.type].length
          "
          :duration="3000"
          class="data_length"
        />
      </template>
      <template #default>
        <div ref="proposalResultWrap">
          <SelectContainer>
            <BaseSelect
              v-model="secondSortSelected"
              :options="secondOptions"
              :selectRef="secondRef"
              @input="backToTop"
            />
          </SelectContainer>

          <template v-for="(row, index) in secondSortedData">
            <div
              class="proposal_result_content"
              :key="index"
              v-if="row && Object.keys(row).length !== 0"
            >
              <BaseInfo>
                <div class="case_name">{{ index + 1 }}.{{ row.活動名稱 }}</div>
                <div class="case_info">
                  <span class="case_type">
                    性質：<time>{{ row.性質 }}</time>
                  </span>
                  <span class="case_time">活動時間：{{ row.活動時間 }}</span>
                  <span class="case_address">活動地點：{{ row.活動地點 }}</span>
                  <span>合作單位：{{ row.合作單位 }}</span>
                </div>
              </BaseInfo>
              <div class="case_content">
                <span class="case_subtitle">活動內容：</span>
                <p>
                  {{ row.活動內容 }}
                </p>
              </div>
              <div v-if="isShowLink(row)" class="case_sns">
                <span class="case_subtitle">相關連結：</span>
                <a :href="row.相關連結1" target="_blank">
                  {{ row.標題1 || row.相關連結1 }}
                </a>
                <a :href="row.相關連結2" target="_blank">
                  {{ row.標題2 || row.相關連結2 }}
                </a>
                <a :href="row.相關連結3" target="_blank">
                  {{ row.標題3 || row.相關連結3 }}
                </a>
                <a :href="row.相關連結4" target="_blank">
                  {{ row.標題4 || row.相關連結4 }}
                </a>
                <a :href="row.相關連結5" target="_blank">
                  {{ row.標題5 || row.相關連結5 }}
                </a>
              </div>
              <div v-if="row.活動照片" class="case_image">
                <p class="case_subtitle">相關照片：</p>
                <Lightbox
                  :items="row.活動照片 && row.活動照片.map((i) => i.url)"
                />
              </div>
            </div>
          </template>
        </div>
      </template>
    </SideNavShell>
  </div>
</template>

<script>
import airtable from "@/mixins/airtable.vue";
import ShellHeader from "@/components/Popover/ShellHeader.vue";
import firstSort from "@/mixins/firstSort.vue";
import secondSort from "@/mixins/secondSort.vue";
import SideNavShell from "./SideNavShell.vue";
import popupVue from "../../mixins/popup.vue";
import Lightbox from "@/components/Lightbox.vue";
import BaseSelect from "./BaseSelect.vue";
import SelectContainer from "./SelectContainer.vue";
import BaseInfo from "@/components/Popover/BaseInfo.vue";

export default {
  name: "CharitableActivity",
  components: {
    ShellHeader,
    SideNavShell,
    Lightbox,
    BaseSelect,
    SelectContainer,
    BaseInfo,
  },
  mixins: [airtable, firstSort, secondSort, popupVue],
  props: {
    displayDefaultValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataType: "",
    };
  },
  computed: {
    sortItems() {
      // console.log(this.firstSortedData);
      return ["霧峰", "沙鹿", "大肚", "烏日", "龍井", "其他"].map((item) => ({
        type: item,
        text: `${item} (${
          this.firstSortedData[item] && this.firstSortedData[item].length
        })`,
      }));
    },
    firstRef() {
      return "活動區域";
    },
    secondRef() {
      return "性質";
    },
    orderSecondOptions() {
      return ["講座", "公益", "活動", "工作坊", "其他"];
    },
  },
  mounted() {
    this.getData("地方公益活動", {
      sort: [{ field: "活動時間", direction: "desc" }],
    });
  },
  watch: {
    dataType(newValue, oldValue) {
      if (!oldValue) return;
      else if (newValue === oldValue) return;
      this.backToTop();
    },
  },
  methods: {
    isShowLink(row) {
      return Object.keys(row).find((keyName) => keyName.includes("相關"));
    },
    backToTop() {
      this.$refs.proposalResultWrap.scroll(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.CharitableActivity {
  @include popup;
}

.survey_case {
  &_category {
    margin-bottom: 16px;
  }
}

.case {
  &_content {
    margin-bottom: 16px;
    p {
      margin: 4px 0 0 0;
      white-space: pre-line;
    }
  }
  &_sns {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    a {
      width: fit-content;
      text-decoration: underline;
    }
  }
}
.proposal_result_content {
  padding: 16px;
  border-bottom: solid 20px $red-600;
  @include media(md) {
    padding: 50px 55px;
  }
  figure {
    display: inline-block;
    p {
      margin: 0;
    }
  }
}
</style>
