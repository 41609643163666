<template>
  <div class="Lightbox">
    <div class="lightbox_gallery">
      <div
        class="lightbox_item"
        v-for="(src, index) in items.slice(0, 4)"
        :key="index"
        @click.prevent="showLightBox(index, src)"
      >
        <img :src="src" alt="" />
        <span
          class="more-image"
          v-if="index == cells - 1 && items.length - cells > 0"
        >
          +{{ items.length - cells }}
        </span>
      </div>
    </div>
    <transition>
      <div class="lightbox_container" v-if="index >= 0">
        <button class="change_button prev" @click="lightboxPrev">
          <changeLightBoxIcon />
        </button>
        <button class="change_button next" @click="lightboxNext">
          <changeLightBoxIcon />
        </button>
        <div class="image_wrap" @click="index = -1">
          <div class="lightbox_image">
            <img :src="src" alt="" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import changeLightBoxIcon from "@/assets/image/icon/next-arrow.svg";
export default {
  name: "Lightbox",
  components: {
    changeLightBoxIcon,
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      src: "",
      index: -1,
      cells: 4,
    };
  },
  methods: {
    closeDetailModal() {
      this.$emit("closeDetailModal", false);
      document.body.appendChild(this.$el);
      document.body.classList.add("modal-open");
    },
    showLightBox(index) {
      if (index >= this.items.length) {
        index = 0;
      }
      if (index < 0) {
        index = this.items.length - 1;
      }
      this.index = index;
      const src = this.items[index];
      this.src = src;
    },
    backgroundImage() {
      return;
    },
    lightboxPrev() {
      this.showLightBox(this.index - 1);
    },
    lightboxNext() {
      this.showLightBox(this.index + 1);
    },
  },
};
</script>

<style lang="scss" scoped>
$image-width: 270px;
$image-height: 180px;

.lightbox {
  &_gallery {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(2, 1fr);
    @include media(lg) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  &_item {
    height: 108px;
    position: relative;
    cursor: pointer;
    @include media(md) {
      height: $image-height;
    }
    @include media(lg) {
      height: $image-height * 1.2;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  &_container {
    min-height: 100%;
    height: 100vh;
    display: block;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
  }
  &_image {
    width: fit-content;
    height: fit-content;
    max-width: 550px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
}

.image_wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  right: 40px;
  @include media(md) {
    left: 100px;
    right: 100px;
  }
}

.change_button {
  width: 50px;
  height: 50px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  svg {
    fill: $white;
  }
}

.more-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 20px;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
  &::before {
    display: inline-block;
    content: "";
    vertical-align: middle;
    height: 100%;
  }
  @include media(md) {
  }
}

.prev {
  width: 40px;
  left: 5px;
  transform: scaleX(-1);
  @include media(md) {
    left: 50px;
  }
}

.next {
  width: 40px;
  right: 5px;
  @include media(md) {
    right: 50px;
  }
}

.v-enter {
  opacity: 0;
}
.v-enter-active {
  transition: opacity 0.5s ease;
}
.v-enter-to {
  opacity: 1;
}
</style>
